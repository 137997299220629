import Vue from 'vue'
import VueI18n from 'vue-i18n'
 
 
Vue.use(VueI18n);
// 使用插件
 
const i18n = {
    locale: window.localStorage.getItem('locale') || 'tc', // 语言标识,第一次登录默认是中文
    messages: {
            // zh: require('./lang/zh'), // 汉语
            en: require('./lang/en.js'), // 英语
            tc: require('./lang/tc.js'), // 繁体
            jp: require('./lang/jp.js'), // 日语
            kr: require('./lang/kr.js'), // 韩语
        }
    }
 
export default i18n