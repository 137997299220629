import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import Antd from 'ant-design-vue';
import VueClipboard from 'vue-clipboard2'
import './global.less'
import 'ant-design-vue/dist/antd.css';
import 'vant/lib/index.css';
import "./assets/iconfont/font_4061959_p6k960kayza/iconfont.css"
import i18n from './locales';
import 'lib-flexible/flexible'
import '@/directives'
import SliderVerificationCode from 'slider-verification-code';
import 'slider-verification-code/lib/slider-verification-code.css';

Vue.use(SliderVerificationCode);
Vue.use(Antd);
Vue.config.productionTip = false
Vue.use(Vant);
Vue.use(VueClipboard)
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
