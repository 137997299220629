import Vue from 'vue'
import VueRouter from 'vue-router'
import HelpCenter from '@/views/HelpCenter/index.vue'
import QueryIccid from '@/views/QueryIccid/index.vue'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
Vue.use(VueRouter)

const routes = [
  {
    path: '/collectingInformation/:loacles?/:role?',
    name: 'collectingInformation',
    component: () => import('@/views/collectingInformation/index.vue'),
  },
  {
    path: '/QueryIccid',
    name: 'queryiccid',
    component: QueryIccid,
    redirect: '/EnterIccid',
    children:[
      {
        path: '/EnterIccid',
        name: 'EnterIccid',
        component: () => import('@/views/QueryIccid/pages/EnterIccid.vue')
      },
      {
        path: '/GetIccid',
        name: 'getIccid',
        component: () => import('@/views/QueryIccid/pages/getIccid.vue')
      },
    ]
  },
  {
    path: '/packages-info',
    name: 'PackagesInfo',
    component: () => import('@/views/QueryAreaPlan/index.vue'),
  },
  {
    path: '/HelpCenterLayout',
    name: 'layout',
    meta: { title:'幫助中心'},
    component: HelpCenter,
    redirect: '/HelpCenter',
    children:[
      {
        path: '/HelpCenter',
        name: 'index',
        meta: { title:'language.chinese',defaultRouter:true },
        component: () => import('@/views/HelpCenter/components/layout.vue')
      },
      {
        path: '/HelpCenter/contactus',
        name: 'contactus',
        meta: { title:'聯繫我們' },
        component: () => import('@/views/HelpCenter/components/ContactUs.vue')
      },
      {
        path: '/HelpCenter/esiminstallpage',
        name: 'esiminstallpage',
        meta: { title:'eSIM 安裝指引' },
        component: () => import('@/views/HelpCenter/components/EsimInstallPage.vue')
      },
      {
        path: '/HelpCenter/esimUsepage',
        name: 'esimUsepage',
        meta: { title:'eSIM 使用和管理' },
        component: () => import('@/views/HelpCenter/components/EsimUsePage.vue')
      },
      {
        path: '/HelpCenter/esimtroubleshooting',
        name: 'esimtroubleshooting',
        meta: { title:'eSIM 故障排查' },
        component: () => import('@/views/HelpCenter/components/EsimTroubleshooting.vue')
      },
    ]
  },
  {
    path: '/RedeemEsimCode/:group/:identify/:loacles',
    name: 'RedeemEsimCodeloacles',
    meta: { defaultRouter:true },
    component: () => import('@/views/RedeemEsimCode/index.vue')
  },
  {
    path: '/iOSTutorial',
    name: 'iOSTutorial',
    component: () => import('@/views/Tutorial/iOSTutorial.vue')
  },
  {
    path: '/AndroidTutorial',
    name: 'AndroidTutorial',
    component: () => import('@/views/Tutorial/AndroidTutorial')
  },
  {
    path: '/contact/:contactLoacle',
    name: 'contact',
    component: () => import('@/views/ContactUs/index.vue')
  },
  // {
  //   path: '/',
  //   redirect:'/RedeemEsimCode/group/identify/loacles',
  //   component: () => import('../views/RedeemEsimCode/index.vue')
  // },
  {
    path: '/RedeemEsimCode/:group/:identify',
    name: 'RedeemEsimCode',
    component: () => import('@/views/RedeemEsimCode/index.vue')
  },
  {
    name: 'notfound',
    path: '/notfound',
    component: () => import('@/views/404.vue')
  },
  // {
  //   path: '*',
  //   redirect: '/notfound'
  // },
  
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {//切换页面时回到顶部
    return { x: 0, y: 0 };
  }
})


export default router
