module.exports =  {
    "collectingInformation": {
        "collaboration": "협력을 기대합니다",
        "wonderfuljourney": "TSim을 선택해 주셔서 감사합니다. 멋진 시작이 될 것입니다.",
        "expandingbusiness": "무한한 비즈니스 기회를 열고 사업을 확장하세요!",
        "workingdays": "2분만 작성하면,  담당자가 2영업일 이내에 연락을 드리겠습니다!",
        "ourclients": `<h1><span>비즈니스파트너</span>가되실래요</h1>`,
        "ourclients2": `<span>비즈니스파트너</span>가되실래요`,
        "optimalbusiness": "업계 최고의 솔루션을 제공하며, 맞춤형 최적의 비즈니스 전략과 가격을 제안드립니다.",
        "oursuppliers": `<h1><span>공급업체</span>가 되실래요</h1>`,
        "oursuppliers2": `<span>공급업체</span>가 되실래요`,
        "businessopportunities": "매력적인 제품으로 전 세계 사용자와 새로운 비즈니스 기회를 열어드립니다.",
        "startednow": "지금 시작하세요!",
        "contactus": `이미 저희 고객이시고 지원이 필요하시다면 아래로 문의해주세요: <a href="https://service.tsimtech.com/contact/en">도움센터 - 문의하기</a>`,
        "supportteam": "지원팀이 최대한 빠르게 연락드리겠습니다!",
        "copyright": "저작권 © 2025 TSim Tech",
        "providesuitable": "2분만 작성하면, 담당자가 2영업일 이내에 연락드려 맞춤형 솔루션과 견적을 제공해드립니다.",
        "aboutcompany": "귀사 정보",
        "aboutbusiness": "귀사의 사업 정보를 알려주세요.",
        "aboutproduct": "제품 정보",
        "understandneeds": "귀사의 요구 사항을 알려주세요.",
        "contactfun": "연락처 정보",
        "contactyou": "연락 방법",
        "companyname": "회사명",
        "entercompany": "회사명을 입력하세요.",
        "countryregion": "국가/지역",
        "enterarea": "국가/지역",
        "yearofcompany": "설립 연도(년)",
        "companywebsite": "웹사이트",
        "saleschannels": "제품 판매 채널 (복수 선택 가능)",
        "ownedplatform": "독립 플랫폼(자체 구축/소유 플랫폼)",
        "otawebsite": "제3자/OTA 플랫폼",
        "mobileapplications": "모바일 앱",
        "offlineretail": "오프라인 매장",
        "otherchannels": "기타 (설명 부탁드립니다)",
        "monthlyquantity": "예상 월간 판매량",
        "Belownum": "3,000 장 미만",
        "newopportunities": "새로운 분야에 도전하세요! 저희가 전방위적으로 지원해 드립니다.",
        "startedquickly": "비즈니스/제품 적합성에서부터 기술 고객 서비스까지,저희는 언제나 준비되어 있습니다. 여러분이 처음부터 빠르게 시작할 수 있도록 지원하겠습니다.",
        "numtonum": "3,000-10,000 장",
        "marketfoundation": "시장 기반을 강화하여, 비즈니스의 새로운 목표를 달성하도록 도와드리겠습니다!.",
        "sustainedgrowth": "전문 팀이 더 넓은 시장에서 새로운 기회를 발굴하여 지속적인 성장을 실현할 수 있도록 지원해 드립니다.",
        "orabove": "10,000 장 이상",
        "forwardrapidly": "귀사의 비즈니스가 빠르게 발전하고 있으며, 저희가 한 걸음 더 나아갈 수 있도록 돕겠습니다!",
        "specification": "비즈니스 범위를 전반적으로 확장하고, 맞춤형 제품 라인을 구축하며, 치열한 경쟁 속에서 돋보이게 하겠습니다.",
        "wishproduct": "관심 있는 제품 유형 (복수 선택 가능)",
        "esim": "eSIM",
        "physical": "uSIM",
        "back": "이전",
        "next": "다음",
        "contactname": "담당자명",
        "enteryourname": "이름을 입력하세요.",
        "email": "이메일 주소",
        "enteryouremail": "이메일 주소를 입력하세요.",
        "getcode": "인증번호 받기",
        "entercode": "인증번호를 입력해 주세요",
        "phonenumber": "전화번호",
        "enterphone": "전화번호를 입력해주세요.",
        "submit": "제출",
        "edit": "수정",
        "confirm": "확인",
        "confidentialityagreement": `저는 <a href="" style="color: #c86d02;text-decoration: underline">비밀유지계약서</a>를 읽고 동의하였습니다.`,
        "wellreceived": "감사합니다! 정보를 접수하였습니다!",
        "keepcontact": "담당자가 2영업일 내로 연락드릴 예정이오니, 연락이 가능하도록 부탁드립니다.진심으로 귀하와의 협력을 기대합니다!",
        "chancesgrowth": "단 2분만 작성해 주시면, 저희 담당자가 2영업일 내로 연락드려 더 많은 비즈니스 성장 기회를 탐색해드립니다.",
        "negotiatebussines": "귀사의 사업 정보를 알려주세요.",
        "negotiatemore": "자세히 알아보기",
        "understandstrengths": "귀하의 강점을 설명합니다.",
        "howcontactyou": "연락 방법",
        "nameofcompany": "회사명",
        "countryregioncity": "국가/지역/도시",
        "havewebsite": "회사 웹사이트 여부",
        "yes": "예",
        "no": "아니오",
        "producttypes": "제공하는 제품 유형 (복수 선택 가능)",
        "globalroaming": "글로벌 로밍 리소스",
        "onlydata": "데이터 전용",
        "datacalls": "데이터 + 통화",
        "datasms": "데이터 +  SMS",
        "datacallssms": "데이터 + 통화 +  SMS",
        "local": "로컬 리소스",
        "preferredregions": "선호 지역 (복수 선택 가능)",
        "asia": "아시아",
        "europe": "유럽",
        "africa": "아프리카",
        "northamerica": "북미",
        "southamerica": "남미",
        "oceania": "태평양 지역",
        "settlementurl": "견적 URL",
        "or": "또는",
        "uploadsettlementurl": "견적서를 업로드해 주세요",
        "supportapi": "API 연동을 지원하시나요?",
        "apiurl": "API URL",
        "uploadapi": "API 문서를 업로드해 주세요",
        "supportcdr": "CDR를 지원합니까?",
        "confirmsubmit": "정보 확인 및 제출",
        "followinginformation": `다음 정보를 제출하시려면 아래 내용을 꼼꼼히 확인한 후 “<b>제출</b>” 버튼을 클릭해 주세요.`,
        "correctcompany": "올바른 회사명을 입력해 주세요.",
        "correctname": "올바른 담당자 이름을 입력해 주세요.",
        "correctemail": "올바른 이메일 주소를 입력해 주세요.",
        "correctverification": "올바른 인증 코드를 입력해 주세요.",
        "selectarea": "회사 지역을 선택해 주세요",
        "selectyear": "회사 설립 연도를 선택해 주세요.",
        "uploadxlsx": "업로드는 .xlsx 파일 형식만 지원됩니다.",
        "uploadpdf": "업로드는 .pdf 파일 형식만 지원됩니다.",
        "upload": "파일을 업로드해 주세요.",
        "entersettlementurl": "견적서 URL을 입력해 주세요.",
        "enterapiurl": "API URL을 입력해 주세요.",
        "apidocument": "API 문서",
        "sec": "초",
        "selectsalef": "제품 판매 채널을 선택해 주세요.",
        "selectmonthsales": "예상 월간 판매량을 선택해 주세요.",
        "selecthopeType": "알고 싶은 제품 유형을 선택해 주세요.",
        "selectareacode": "국제 지역 번호를 입력하고 선택하세요.",
        "areacode":"국제 전화",
        "pleaseenter": "필수 항목에 입력해 주세요.",
        "confirmagreement": "비밀유지동의서를 읽고 동의했는지 확인하세요.",
        "selectprovidingtype": "제공하는 제품 유형을 선택해 주세요.",
        "selectresources": "제품 리소스 유형을 선택해 주세요.",
        "selectprefereregions": "선호 지역을 선택해 주세요.",
        "selectisapi": "API 접속 가능 여부를 선택해 주세요.",
        "selectiscdr": "CDR을 볼 수 있는지 여부를 선택해 주세요.",
        "selectyear2":"선택해 주세요",
        "enterwebsite":"URL을 정확하게 입력해 주세요",
        "commerceplatform":"온라인 쇼핑 사이트",
        "travelplatform":"온라인 여행사 플랫폼",
        "entercountrytip":"국가명/국제전화번호를 입력하여 검색 가능합니다.",
    },
}